<template>
  <div class="content-box">
    <p style="text-align: center">中付商户服务协议</p>
    <p>
      中付商户服务协议（以下简称&ldquo;本协议&rdquo;）是中付支付科技有限公司（以下简称&nbsp;&ldquo;我司&rdquo;）与商户就本移动客户端应用程序服务及其他增值服务（以下简称&ldquo;本服务&rdquo;）等相关事项所订立的有效协议。
    </p>
    <p>
      <strong>商户通过移动客户端应用程序界面点击或以其他方式同意</strong
      ><strong>/确认/接受本协议，即表示</strong
      ><strong
        >商户确认我司已充分提示商户对本协议的各条款作全面、准确的理解，商户确认已完全了解本协议全部内容，商户与我司已就本协议全部内容达成协议并同意接受本协议的全部约定内容。</strong
      >
    </p>
    <p>
      <strong>在接受本协议之前，请商户仔细阅读本协议的全部内容，</strong
      ><strong>特别是其中所涉及的免除或限制</strong><strong>我司</strong
      ><strong>责任的条款等</strong
      ><strong
        >。如果商户不同意本协议的任何内容，或者无法准确理解该条款的含义，请不要进行后续操作。</strong
      ><strong>&nbsp;</strong>
    </p>
    <h3>
      <strong>一</strong><strong>、</strong><strong>中付账户</strong
      ><strong>的注册</strong>
    </h3>
    <p>在使用本服务前，商户必须先注册中付账户，并同意以下事项：</p>
    <p>
      1、商户应具有中国法律规定的完全民事权利能力和民事行为能力，能够独立承担民事责任。<strong
        >若商户</strong
      ><strong>不具备</strong><strong>前述</strong><strong>条件，应立即</strong
      ><strong>停止</strong><strong>注册和使用本服务。</strong>
    </p>
    <p>
      2、商户使用本服务前应通过信息认证（信息认证要素包括但不限于经营证照信息、法人姓名、法人身份证号、结算银行账户等，具体信息认证要素以服务页面展示内容为准）后方可注册中付账户，并自行设置登录密码。<strong
        >商户应</strong
      ><strong>妥善保管账户</strong><strong>和密码</strong><strong>，</strong
      ><strong>不应转让或借予他人</strong><strong>使用</strong
      ><strong>，商户</strong><strong>应</strong><strong>对</strong
      ><strong>以</strong><strong>该</strong><strong>账户</strong
      ><strong>进行的一切活动</strong><strong>承担</strong
      ><strong>全部法律责任。如因</strong><strong>商户泄露</strong
      ><strong>密码和身份信息导致无法使用</strong><strong>本协议</strong
      ><strong>项下服务或资金被盗等</strong><strong>后果</strong
      ><strong>均由商户自行</strong><strong>承担</strong
      ><strong>，与我司无关。</strong>
    </p>
    <p>
      3、商户理解并同意，根据监管机构要求，商户如需使用更全面的服务，需要进一步完善认证信息（具体认证要素以服务使用页面展示内容为准）。
    </p>
    <p>
      4、<strong>商户同意并授权，就</strong><strong>商户</strong
      ><strong>注册</strong><strong>中付账户和</strong><strong>使用</strong
      ><strong>本</strong><strong>服务时向我司提供</strong
      ><strong>的认证</strong><strong>信息</strong><strong>均为商户合法</strong
      ><strong>信息，我司有权</strong><strong>通过征信机构</strong
      ><strong>、</strong><strong>银行</strong><strong>或其他第三方</strong
      ><strong>机构</strong><strong>验证商户的信息</strong><strong>或</strong
      ><strong>通过</strong><strong>第三方</strong
      ><strong>获得商户的相关信息</strong><strong>，相应</strong
      ><strong>的</strong><strong>验证</strong><strong>结果均由商户</strong
      ><strong>自行</strong><strong>承担</strong
      ><strong>。</strong>商户进行身份认证时应同时遵守以下内容：
    </p>
    <p>
      <strong>（</strong><strong>1）不得冒用任何第三</strong
      ><strong>者名义</strong><strong>进行</strong><strong>身份</strong
      ><strong>认证；</strong>
    </p>
    <p>
      <strong>（</strong><strong>2）有义务维护和更新商户的信息，</strong
      ><strong>确保</strong><strong>其持续的准确性、完整性和有效性。</strong
      ><strong
        >在使用电子支付服务的过程中，商户所提供的证照、文件、资料信息、业务状况（如经营情况、商品和服务以及提供方式）、商户基本情况（如资本及所有权）如有变更，均应在变更后五个工作日内主动以书面形式通知我司，并按我司规定的程序办理有关手续。商户独立承担因其提供的资料和信息不准确、不真实、不完整、不及时等情况而引起的一切法律责任和后果。</strong
      >
    </p>
    <p>
      <strong>（</strong><strong>3）承担因</strong><strong>提交</strong
      ><strong>的</strong><strong>认证</strong><strong>信息缺失、</strong
      ><strong>已过有限期</strong><strong>或者不真实而造成的</strong
      ><strong>全部</strong><strong>损失</strong><strong>。若</strong
      ><strong>我司有合理理由</strong><strong>怀疑商户</strong
      ><strong>的信息不真实、不合法或</strong><strong>已过有效期</strong
      ><strong>的，我司有权不经通知先行暂停或终止为</strong><strong>商户</strong
      ><strong>提供任何服务；</strong>
    </p>
    <p>
      <strong>（</strong><strong>4）</strong><strong>商户</strong
      ><strong>同意我司保存商户提交的</strong><strong>认证</strong
      ><strong>信息</strong><strong>（中付特约商户注册信息表）</strong
      ><strong>。</strong>
    </p>
    <table border="1" style="border-collapse: collapse">
      <tbody>
        <tr>
          <td colspan="4" width="553">
            <p style="text-align: center">中付特约商户注册登记表</p>
          </td>
        </tr>
        <tr>
          <td width="103">
            <p>营业执照号</p>
          </td>
          <td width="179">
            <p>
              {{ params.businessRegno || "-" }}
            </p>
          </td>
          <td width="85">
            <p>法定代表人姓名</p>
          </td>
          <td width="184">
            <p>
              {{ params.legalName || "-" }}
            </p>
          </td>
        </tr>
        <tr>
          <td width="103">
            <p>法定名称</p>
          </td>
          <td width="179">
            <p>
              {{ params.merchantType === 1 ? "-" : params.cupsMerchantName }}
            </p>
          </td>
          <td rowspan="2" width="85">
            <p>身份证号</p>
          </td>
          <td rowspan="2" width="184">
            <p>
              {{ params.legalidCardNo || "-" }}
            </p>
          </td>
        </tr>
        <tr>
          <td width="103">
            <p>经营名称</p>
          </td>
          <td width="179">
            <p>
              {{ params.cupsMerchantName || "-" }}
            </p>
          </td>
        </tr>
        <tr>
          <td width="103">
            <p>所在地区</p>
          </td>
          <td width="179">
            <p>
              {{ params.merArea || "-" }}
            </p>
          </td>
          <td width="85">
            <p>有效期</p>
          </td>
          <td width="184">
            <p>
              {{ params.legalidEffectTime }} -
              {{ params.legalidValidTime }}
            </p>
          </td>
        </tr>
        <tr>
          <td width="103">
            <p>经营地址</p>
          </td>
          <td colspan="3" width="449">
            <p>
              {{ params.addressDetail || "-" }}
            </p>
          </td>
        </tr>
        <tr>
          <td width="103">
            <p>银行账号</p>
          </td>
          <td width="179">
            <p>
              {{ params.screenNum || "-" }}
            </p>
          </td>
          <td width="85">
            <p>开户名称</p>
          </td>
          <td width="184">
            <p>
              {{ params.commissary }}
            </p>
          </td>
        </tr>
        <tr>
          <td width="103">
            <p>开户银行</p>
          </td>
          <td width="179">
            <p>
              {{ params.bankName || "-" }}
            </p>
          </td>
          <td width="85">
            <p>身份证号</p>
          </td>
          <td width="184">
            <p>
              {{ params.accountNum || "-" }}
            </p>
          </td>
        </tr>
        <tr>
          <td width="103">
            <p>签购单名称</p>
          </td>
          <td width="179">
            <p>
              {{ params.cupsMerchantName || "-" }}
            </p>
          </td>
          <td width="85">
            <p>商户类别</p>
          </td>
          <td width="184">
            <p>
              {{ params.mcc || "-" }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <h3>
      <strong>二</strong><strong>、</strong><strong>中付商户</strong
      ><strong>服务</strong>
    </h3>
    <p>我司提供的商户服务主要如下：</p>
    <p>
      <strong>1</strong><strong>、收单</strong><strong>服务：</strong
      ><strong>为</strong><strong>商户提供</strong><strong>收单</strong
      ><strong>服务，服务类型包括</strong><strong>并不限：银行卡</strong
      ><strong>刷卡支付</strong><strong>（含挥卡</strong
      ><strong>非接支付</strong><strong>）</strong><strong>、</strong
      ><strong>条码</strong><strong>支付</strong
      ><strong>、互联网支付等；</strong><strong>支付类型</strong
      ><strong>以应用程序界面展示内容</strong><strong>为准</strong
      ><strong>。</strong><strong>&nbsp;</strong>
    </p>
    <p>
      <strong>2、</strong><strong>结算服务：按照</strong><strong>结算</strong
      ><strong>周期</strong><strong>规则</strong><strong>向</strong
      ><strong>商户提供结算服务</strong><strong>，将商户交易</strong
      ><strong>资金</strong><strong>划拨至</strong><strong>其</strong
      ><strong>与中付支付</strong><strong>账户所</strong
      ><strong>绑定的商户自有银行账户（仅限</strong><strong>借记卡</strong
      ><strong>或对公银行账户），包括商户服务过程</strong><strong>中</strong
      ><strong>变更</strong><strong>的</strong><strong>商户自有银行</strong
      ><strong>账户</strong><strong>。</strong>
    </p>
    <p>
      <strong>结算周期：我</strong><strong>司向商户提供的结算</strong
      ><strong>周期服务</strong><strong>，具体规则以</strong
      ><strong>客户端</strong><strong>服务页面</strong><strong>展示内容</strong
      ><strong>为准</strong><strong>。</strong><strong>&nbsp;</strong>
    </p>
    <p>
      <strong>注</strong><strong>：</strong><strong>中付支付账户开立不满</strong
      ><strong>90日或者中付支付账户开立后连续正常交易不满30日的</strong
      ><strong>商户，我司</strong><strong>有权拒绝</strong
      ><strong>向其提供资金实时结算服务。</strong>
    </p>
    <p>
      3、信用卡申请：商户可通过本应用程序向我司提交信用卡申请的信息，并授权我司将商户法人信息向银行提交的服务。
    </p>
    <p>
      <strong
        >因法律法规或政策变化、我司业务调整等原因，我司有权根据需要增加、减少或调整服务内容，并同时调整相应服务规则，届时我司将通过本应用程序界面公告的方式予以公布，不做另行通知。调整后的内容自公告期届满（公告期为</strong
      ><strong>10</strong
      ><strong
        >日）之日起生效。若商户在我司公告生效后继续使用本服务的，表示商户已充分阅读、理解并接受调整的规则内容，否则商户应当立即停止使用本服务。</strong
      >
    </p>
    <h3><strong>三</strong><strong>、</strong><strong>确认与承诺</strong></h3>
    <p>
      <strong>1、</strong
      ><strong
        >商户应妥善保管中付账号、登录密码、中付支付账户交易密码及身份认证信息，因商户保管不当所产生的一切损失由商户自行承担，并赔偿因此给我司或其他主体造成的损失。商户如发现有他人冒用或盗用账号登录名及密码或任何其他未经合法授权之情形，或发生其他可能危及到中付支付账户资金安全情形时，应立即以有效方式通知我司，申请暂停相关服务。商户理解并知悉，我司对商户请求采取的暂停服务等操作需要合理期限，在此之前，我司对已执行的指令所导致的商户损失不承担任何责任。</strong
      >
    </p>
    <p>
      <strong>2、</strong><strong>商户依据本协议所开立的中付支付账</strong
      ><strong>户</strong><strong>仅限商户</strong><strong>自身</strong
      ><strong
        >使用，不得擅自转让、出租、出售、出借、购买，否则，由此产生的一切风险和损失均由商户自行承担。我司有合理怀疑或查实之时，有权立即单处或者并处冻结交易款项、延迟资金结算、中止或者终止服务等措施，发现涉嫌违法犯罪的，我司有权立即向公安机关等有关单位报告。出租、出借、出售、购买中付支付账户的惩戒措施为：经设区的市级及以上公安机关认定出租、出借、出售、购买中付支付账户的，或者假冒他人身份或者虚构代理关系开立账户的，我司有权于5年内暂停涉案支付账户的所有业务，并拒绝开立新的支付账户，该惩戒期满后，我司有权对开立新的支付账户加大审核力度。中国人民银行等监管机构有权将出租、出借、出售、购买中付支付账户的相关信息移送金融信用信息基础数据库并向社会公布。商户确认已充分了解并清楚知晓上述转让、出租、出售、出借、购买中付支付账户的相关法律责任和惩戒措施，承诺依法依规开立和使用中付支付账户。</strong
      >
    </p>
    <p>
      <strong
        >3、商户确认：商户开立中付支付账户，已经征得商户法定代表人（负责人）的同意。</strong
      >
    </p>
    <p>
      <strong
        >4、针对我司收到的以商户账号及密码登录并发起的任何交易请求，商户均无条件承认为其真实意思表示，商户应对以该账号进行的一切活动承担全部法律责任。</strong
      >
    </p>
    <p>
      <strong>5、</strong
      ><strong
        >商户应合理使用中付账号，不得从事任何违反法律、法规或具有约束力的规范性文件的行为；不得对账号使用过程中的任何数据进行拦截、破解、篡改或对账号系统进行非正常的登录。</strong
      >
    </p>
    <p>
      <strong>6、</strong
      ><strong
        >商户应自行查询中付支付账户资金和使用情况，并定期保存交易历史记录，发现任何异常或疑问应及时联系我司客户服务人员。</strong
      >
    </p>
    <p>
      <strong>7、</strong><strong>仅限商户</strong><strong>自身</strong
      ><strong
        >或经合法持卡人授权使用的服务，不可撤销地授权我司根据商户指令完成相应资金划付。商户应确保提交信息的准确性（包括但不限于收款方身份信息、银行账户、手机号码等）。因商户提交信息错误所导致的资金损失，我司不承担任何责任，亦不提供任何解决途径，商户应自行处理。我司根据商户指令完成相应服务的即视为我司已按本协议约定履行当次服务义务。</strong
      >
    </p>
    <p>
      <strong>8</strong
      ><strong
        >、商户不得利用本服务从事侵害他人合法权益的行为，否则我司有权拒绝提供本服务，且商户应承担所有相关法律责任。因商户的行为导致我司损失的（包括但不限于对他方的先行赔付，以及为实现债权支出的诉讼/仲裁费、财产保全费、保全担保费、律师费等费用），我司有权向商户进行追偿。违法行为包括但不限于：</strong
      >
    </p>
    <p>
      <strong>（</strong><strong>1）</strong
      ><strong>冒用他人名义使用本服务；</strong>
    </p>
    <p>
      <strong>（</strong><strong>2</strong><strong>）</strong
      ><strong>从事洗钱、恐怖融资等</strong><strong>行为；</strong>
    </p>
    <p>
      <strong>（</strong><strong>3</strong><strong>）</strong
      ><strong
        >非法使用他人银行账户（包括信用卡账户）或无效银行账号（包括信用卡账户）</strong
      ><strong>；</strong>
    </p>
    <p>
      <strong>（</strong><strong>4</strong><strong>）商户</strong
      ><strong>或</strong><strong>商户</strong
      ><strong>员工与持卡人或其他第三方勾结，或</strong
      ><strong>商户、商户</strong
      ><strong
        >员工使用银行卡以虚拟交易涉嫌从事或被他人利用从事套取现金、洗钱、恐怖融资、赌博、传销等违法犯罪活动；</strong
      >
    </p>
    <p>
      <strong>（</strong><strong>5</strong
      ><strong
        >）对账户使用过程中的任何计算机数据进行拦截、破解、篡改或对账户服务系统进行非正常的登录；</strong
      >
    </p>
    <p>
      <strong>（</strong><strong>6</strong
      ><strong
        >）商户将其他未签约商户的交易在安装在商户的受理终端上受理银行卡，假冒商户交易与我司结算；</strong
      >
    </p>
    <p>
      <strong>（</strong><strong>7</strong
      ><strong
        >）在持卡人不知情的情况下，商户利用持卡人账户信息编造虚假交易或在持卡人消费时重复受理银行卡，并冒用持卡人签名进行虚假交易；</strong
      >
    </p>
    <p>
      <strong>（8）商户或商户的</strong
      ><strong
        >员工默许、纵容或与不法分子共谋在受理终端上装载侧录仪器，盗录持卡人磁条信息，出卖给伪卡制作集团或自行制作伪卡；</strong
      >
    </p>
    <p>
      <strong>（</strong><strong>9）</strong><strong>商户或商户的</strong
      ><strong>员工违反</strong><strong>银行</strong
      ><strong>卡账户信息与交易数据安全管理的规定，违规使用、存储、传输</strong
      ><strong>银行卡</strong><strong>账户信息与交易数据，导致</strong
      ><strong>银行卡</strong><strong>账户信息与交易数据泄露或发生</strong
      ><strong>银行卡</strong
      ><strong>账户信息与交易数据被篡改、破坏等情形；</strong>
    </p>
    <p>
      <strong>（</strong><strong>10</strong
      ><strong
        >）商户未经我司许可，擅自将受理终端机具从我司处登记的原始装机地址转移至另一地址，包括但不限于以下情形：移机后地址与原始装机地址的省市、区县、乡镇等行政区域，或与原始装机地址的道路名称、门牌号码、楼层、房间号、摊位号等不一致；同一商户在多家分店之间自行调换受理终端机具；使用固定受理终端机具进行上门或流动收款等业务；擅自转移受理终端设备到境外受理银行卡交易；</strong
      >
    </p>
    <p>
      <strong>（1</strong><strong>1</strong
      ><strong
        >）商户名义上经营范围正常，或以正常名义申请成为特约商户后，实际从事禁入商户类型的经营活动，或实际的经营情况与商户营业执照上登记的经营范围不符；</strong
      >
    </p>
    <p>
      <strong>（1</strong><strong>2</strong
      ><strong
        >）拒绝配合调单或不能提供有效交易单据，造成发卡机构退单且逃避承担退单责任的；</strong
      >
    </p>
    <p>
      <strong>（1</strong><strong>3</strong
      ><strong
        >）因欺诈交易已被司法机关立案或介入调查；及我司认定商户存在其他违法违规行为。</strong
      >
    </p>
    <p>
      <strong>9</strong
      ><strong
        >、商户应遵守中国人民银行、银保监会、银联、网联及国家或行业的法律法规、监管规定（包括但不限于支付业务及信息安全等方面），加强商户相关人员的风险培训与日常管理，并承担自身风险管理和评估的职责以及违反相关规定的所有责任。</strong
      >
    </p>
    <p>
      <strong>10、</strong
      ><strong
        >商户应当严格遵守和执行中国人民银行、银保监会等监管机构以及银联、网联等清算机构、自律组织以及银行和我司制定</strong
      ><strong>/要求的技术/操作/安全规范等相关规定，否则，</strong
      ><strong
        >我司可以暂停向商户提供电子支付服务且商户应承担全部责任。商户因服务问题导致客户向银行有效投诉超过三次的，我司可以暂停向商户提供电子支付服务。</strong
      >
    </p>
    <p>
      <strong><u>11、</u></strong
      ><strong><u>商户知悉</u></strong
      ><strong><u>并确认，如</u></strong
      ><strong><u>商户</u></strong
      ><strong><u>申请</u></strong
      ><strong><u>在我司处</u></strong
      ><strong><u>开立</u></strong
      ><strong><u>支付</u></strong
      ><strong><u>账户，</u></strong
      ><strong
        ><u
          >该支付账户所记录的资金余额不同于商户本人的银行存款，不受《存款保险条例》保护，其实质为商户委托我司保管的、所有权归属于商户的预付价值。该预付价值对应的货币资金虽然属于商户，但不以商户本人名义存放在银行，而是以我司名义存放在由中国人民银行指定的监管备付金银行账户，并且由我司向银行发起资金调拨指令。</u
        ></strong
      ><strong><u>&nbsp;</u></strong
      ><strong><u>商户</u></strong
      ><strong><u>申请</u></strong
      ><strong><u>在我司处</u></strong
      ><strong><u>开立</u></strong
      ><strong><u>支付</u></strong
      ><strong><u>账户</u></strong
      ><strong><u>应</u></strong
      ><strong><u>同时</u></strong
      ><strong><u>签署</u></strong
      ><strong><u>附录</u></strong
      ><strong><u>。</u></strong>
    </p>
    <p>
      <strong>12</strong><strong>、商户使用</strong><strong>信用卡申请</strong
      ><strong>等实际</strong><strong>由第三方提供的</strong
      ><strong
        >服务时，应遵守该服务的相应规则，否则我司有权不经通知先行暂停提供该服务。</strong
      >
    </p>
    <p>
      <strong>13</strong
      ><strong
        >、在发生任何涉嫌违法违规交易时，商户应根据司法机关、银行、</strong
      ><strong>监管机构</strong
      ><strong>及我司的要求提供涉嫌违法违规交易的相关证明。</strong>
    </p>
    <p>
      <strong>14</strong><strong>、</strong><strong>商户</strong
      ><strong>申请使用电子支付</strong><strong>服务</strong
      ><strong>功能的，应按提示向我司提供</strong><strong>商户</strong
      ><strong
        >真实、完整、有效的相关资料，经我司审核通过后为商户开通本服务。商户承担因资料缺失、已过有效期或不真实而造成的任何损失。若我司有合理理由怀疑商户提供的资料不真实、不合法或已过有效期的，我司有权不经通知先行暂停或终止为商户提供本服务；商户同意，此种情况下我司有权冻结商户的待结算资金及中付账户余额。商户不得冒用任何第三方名义进行申请，亦禁止以任何方式向任何第三方转让本协议项下权利义务。</strong
      >
    </p>
    <p>
      15、<strong>商户使用</strong><strong>我司</strong><strong>的电子</strong
      ><strong>支付服务</strong
      ><strong
        >应基于真实、合法的交易背景，能够提供充分证据证明商户与持卡人之间确实存在交易关系。如商户存在通过虚假交易以现金形式套取信用卡等贷记渠道的资金等行为即套现行为，我司有权关闭电子支付服务功能。造成我司损失的，商户应向我司赔偿损失。</strong
      >
    </p>
    <p>
      <strong>16</strong
      ><strong>、商户使用中付结算服务功能的，应遵守以下规定：</strong>
    </p>
    <p>
      <strong>（</strong><strong>1）</strong
      ><strong
        >商户应为结算银行账户的合法持有者，对该银行账户的使用不得侵犯任何第三人的合法权益，否则我司有权按照银行及银联相关规定采取相应措施；因此给合法持卡人造成损失的，我司有权从待结算资金或中付账户余额中扣收相应款项，待结算资金、中付账户余额均不足以扣收的，我司有权要求商户补足。</strong
      >
    </p>
    <p>
      <strong>（</strong><strong>2</strong
      ><strong
        >）商户应妥善保存银行账户、密码等信息，商户承担对账户和密码保管及使用不当而造成的一切损失。</strong
      >
    </p>
    <p>
      <strong>（</strong><strong>3</strong
      ><strong
        >）商户应对支付指令的真实性、准确性、合法性负责，我司依照商户指令进行操作的一切风险由商户自行承担。</strong
      >
    </p>
    <p>
      <strong>（</strong><strong>4</strong
      ><strong
        >）商户不得利用本服务实施套现、虚假交易、洗钱等任何违法犯罪行为，并应配合我司进行风险调查。</strong
      >
    </p>
    <p>
      <strong>（</strong><strong>5）</strong
      ><strong
        >如银行对商户单笔最高金额，日累计最高金额限制导致商户交易失败的，我司不承担责任。</strong
      >
    </p>
    <p>
      17、<strong>如由于商户自身交易欺诈或商户出现可疑</strong
      ><strong>交易或持卡人</strong><strong>否认</strong
      ><strong>交易等风险交易</strong
      ><strong
        >的，导致持卡人出现资金损失的，由商户自行承担先行赔付责任，</strong
      ><strong>如因此导致</strong><strong>我司</strong><strong>损失的，</strong
      ><strong>商户</strong><strong>应当予以赔偿</strong><strong>。</strong>
    </p>
    <p>
      <strong>18</strong
      ><strong
        >、商户不得存储银行卡磁道信息、卡片验证码、个人标识代码及卡片有效期、生物识别信息等持卡人敏感信息。若因商户的原因发生持卡人信息泄露等问题，由商户承担所有风险及责任；给我司造成损失的，我司有权要求商户赔付。</strong
      >
    </p>
    <p>
      <strong>19</strong
      ><strong
        >、商户在受理银行卡时，应仔细核对持卡人亲笔签名与银行卡背面的预留签名是否一致，如发现签名不一致或卡片有伪造痕迹等其他任何疑问应拒绝交易。商户未按照要求受理银行卡而导致的经济损失，均由商户承担。</strong
      >
    </p>
    <p>
      <strong>20</strong
      ><strong
        >、商户应保证电子签购单上信息与商户消费凭证、银行卡等信息保持一致，并妥善保管持卡人签字确认的电子签购单及相关凭证至少五年以上。</strong
      >
    </p>
    <p>
      <strong>21</strong
      ><strong
        >、对于疑似盗卡交易，商户应配合我司及银行对未消费资金进行冻结。同时，商户应尽可能采取必要防范措施防止此类疑似盗卡交易。</strong
      >
    </p>
    <p>
      <strong>22</strong
      ><strong
        >、持卡人对交易产生异议的，商户有义务配合并向我司提供相关书面文件，包括：电子签购单、消费水单等凭证。商户未能履行本义务而导致我司遭受损失，商户应向我司赔偿相关损失。如因商户无法及时提供证据或所提供证据不被银联或</strong
      ><strong>发卡行</strong
      ><strong>采信而导致发卡行或持卡人拒付的，商户应向持卡人全额退款。</strong>
    </p>
    <p>
      <strong>23</strong
      ><strong
        >、为防控交易风险，商户有积极协助我司处理风险事件、调查可疑交易的义务。我司有权在风险事件发生时，要求商户在合理期限前提供相关辅助材料及情况说明等相关内容以证明其行为的正常。对于商户发生的风险事件，我司有权在商户提供情况说明、澄清交易事实前，冻结商户</strong
      ><strong>待结算</strong><strong>资金或中付</strong
      ><strong>账户余额</strong
      ><strong
        >作为保证金。若商户拒绝提供或无法证明其行为正常的，则我司有权拒绝或停止为其提供服务。</strong
      >
    </p>
    <p>
      <strong>若我司发现商户或商户</strong><strong>法定代表人</strong
      ><strong>、</strong><strong>负责人</strong
      ><strong
        >存在于中国支付清算协会、银行卡清算机构及我司所建立的商户黑名单内的，则我司有权经核实后于</strong
      ><strong>10日内予以清退。</strong>
    </p>
    <p>
      <strong>24</strong
      ><strong
        >、若出现因商户的原因导致中国银联或中国人民银行对商户进行处罚款项的，商户应按实际发生的资金损失进行赔付；给我司造成损失的，我司有权要求商户赔付。</strong
      >
    </p>
    <p>
      <strong>25</strong
      ><strong
        >、根据法律法规及相关监管机关要求，我司有权依据监管规定对商户中付账户向第三方的支付账户或银行账户的日转账限额和日转账交易笔数做出限制，如商户当天交易额或交易笔数已达当日限额，我司有权暂停商户当日交易。</strong
      >
    </p>
    <p>
      <strong>26</strong
      ><strong
        >、我司对商户提供的信息负有依法保护义务，但有权根据法律法规规定或商户授权，为认证商户信息或完成支付指令等</strong
      ><strong>其他为</strong><strong>提供本应用</strong
      ><strong>程序的服务</strong><strong>而向第三方提供。</strong>
    </p>
    <p>
      <strong>27</strong><strong>、我司负责受理涉及本应用程序</strong
      ><strong>的</strong
      ><strong
        >服务的投诉并解决相应问题，我司对于商户与持卡人之间因商品、服务等发生的纠纷不承担责任，如因商户与持卡人的纠纷给我司造成损失的，我司有权要求商户赔付。</strong
      >
    </p>
    <p>
      <strong>28</strong
      ><strong
        >、我司承担本应用程序系统运行，保证系统正常稳定运行，向商户提供本协议所约定之各项服务。</strong
      >
    </p>
    <p>
      <strong>29</strong
      ><strong
        >、基于交易安全和系统维护的需要，我司有权提前3个工作日通过本应用程序界面公示的情形下，对交易系统进行计划性的停机。</strong
      >
    </p>
    <p>
      <strong>30</strong
      ><strong
        >、当商户中付支付账户出现异常或超过六个月无任何使用时，我司有权关闭商户中付支付账户。</strong
      >
    </p>
    <p>
      <strong>31</strong
      ><strong
        >、商户应当积极配合我司履行反洗钱相关法律义务（包括但不限于客户身份识别、客户尽职调查、反洗钱宣传培训等），否则我司有权拒绝为商户或其客户提供相关服务，且不承担任何责任。若因商户不配合我司履行反洗钱相关法律义务而造成我司遭受包括但不限于经济、名誉、信用等各类损失时，商户应对我司予以相应赔偿。当我司根据反洗钱相关法律法规和监管要求请商户提供有关证照、文件、经营情况说明、交易相关证明材料、辅助材料等各类资料和信息时，商户应当在规定时限内按要求及时提供真实、完整、有效的各类资料和信息。</strong
      >
    </p>
    <p>
      <strong>32</strong><strong>、商户</strong
      ><strong
        >的工商注册名称、主营业务、经营范围、法定代表人或主要负责人、银行卡交易资金结算账户信息等重要信息发生变更时，</strong
      ><strong>我司</strong><strong>有权重新审核</strong><strong>商户</strong
      ><strong>银行卡受理资质。当</strong><strong>商户</strong
      ><strong>不再具备银行卡受理资质时，</strong><strong>我司</strong
      ><strong>有权单方面无条件终止本协议。</strong>
    </p>
    <p>
      <strong>33</strong><strong>、</strong><strong>如因</strong
      ><strong>商户</strong><strong>原因，导致</strong><strong>我司</strong
      ><strong>对第三方承担损害赔偿责任（包括但不限于</strong
      ><strong>中国</strong
      ><strong
        >人民银行等国家机关罚没、银联违约责任、律师费、调查费用和其他合理支出等）的，</strong
      ><strong>我司</strong><strong>有权向</strong><strong>商户</strong
      ><strong>追索。</strong>
    </p>
    <p>
      <strong>3</strong><strong>4</strong
      ><strong
        >、商户的工商注册名称、主营业务、经营范围、法定代表人或主要负责人、银行卡交易资金结算账户信息等重要信息发生变更时，我司有权重新审核商户银行卡受理资质。当商户不再具备银行卡受理资质时，我司有权单方面无条件终止本协议。</strong
      >
    </p>
    <p>
      <strong>3</strong><strong>5</strong
      ><strong
        >、对于因操作不当等商户原因造成的短款，我司可协助商户进行追款，但我司并无义务向商户保证可追回相关款项。</strong
      >
    </p>
    <p>
      <strong>3</strong><strong>6</strong
      ><strong
        >、对于商户与持卡人在出售商品的质量、数量或提供的服务上有任何争议、投诉或其他纠纷，应由商户与持卡人直接谋求解决，我司可从中协调但不承担任何责任。</strong
      >
    </p>
    <p>
      <strong>3</strong><strong>7</strong
      ><strong
        >、本协议终止后，我司有权收回协议终止前两年内的交易签购单据、凭证及其他我司交给商户的资料或物品。本协议终止后24个月内，我司对本协议终止前的交易仍有向商户调单及追索的权利，法律另有规定的除外。</strong
      >
    </p>
    <p>
      <strong>38</strong
      ><strong
        >、发生以下情况时，我司可以从商户应收的银行卡交易结算资金或商户在我司款项中抵扣相应款项。如商户银行卡交易结算资金或商户在我司款项不足抵扣时，我司应及时通知商户补足差额款项，商户应自我司通知补足差额款项之日（含）起三个工作日内补足；若商户未补足，我司有权向商户追索。</strong
      >
    </p>
    <p><strong>（</strong><strong>1）发卡机构退单；</strong></p>
    <p>
      <strong>（</strong><strong>2）</strong><strong>商户发生退货交易；</strong>
    </p>
    <p>
      <strong>（</strong><strong>3）由于计算错误或其他原因导致</strong
      ><strong>我司向商户多支付的款项或其他经商户确认的长款；</strong>
    </p>
    <p>
      <strong>（</strong><strong>4）</strong
      ><strong
        >商户拖欠应支付我司的系统服务费、设备服务费、物联网增值服务费、违约金、我司垫付款项或应赔偿款项；</strong
      >
    </p>
    <p>
      <strong>（</strong><strong>5）其他应由</strong
      ><strong>商户支付的款项。</strong>
    </p>
    <p>
      39、对有疑义的交易，我司有权向商户调单，商户应自我司通知之日（含）起三个工作日内提供有效签购单及相关交易证明材料，因商户未能在规定时限内提供有效签购单及相关交易证明材料造成的相关损失和风险责任由商户承担；发生退单业务时，我司应及时通知商户；商户应按照我司的要求提交调账（退货）书面申请，以及有效签购单及相关交易证明材料；商户发生长款或退货交易时，应按我司要求将长款或退货资金足额退还我司；因商户未按我司要求进行差错业务处理造成的相关损失和责任由商户承担。
    </p>
    <h3>
      <strong>四</strong><strong>、</strong><strong>服务费用及</strong
      ><strong>营销奖励</strong>
    </h3>
    <p>
      <strong>1、</strong><strong>商户在使用本服务时，需按照本协议约定的</strong
      ><strong>收费</strong><strong>类型</strong><strong>及收费标准</strong
      ><strong>，向我司及合作方</strong><strong>支付服务费用</strong
      ><strong
        >（包括但不限于系统服务费、使用服务产生的交易手续费、物联网</strong
      ><strong>增值</strong><strong>服务费</strong><strong>等</strong
      ><strong
        >）其中，系统服务费及物联网增值服务费商户同意由我司以代收代付的方式，将费用支付给相应的服务提供方；交易手续费则按协议约定收取。</strong
      >
    </p>
    <p><strong>收费标准如下：</strong></p>
    <table border="1" style="border-collapse: collapse">
      <tbody>
        <tr>
          <td width="103">
            <p>贷记卡</p>
            <p>费率</p>
          </td>
          <td width="85">
            <p>
              {{ params.cuRateCredit || "-" }}
            </p>
          </td>
          <td width="85">
            <p>借记卡</p>
            <p>费率</p>
          </td>
          <td width="66">
            <p>
              {{ params.cuRateDebit || "-" }}
            </p>
          </td>
          <td width="83">
            <p>借记卡</p>
            <p>封顶费率</p>
          </td>
          <td width="130">
            <p>
              {{ params.cuDebitMaxFee || "-" }}
            </p>
          </td>
        </tr>
        <tr>
          <td rowspan="2" width="103">
            <p>银联</p>
            <p>云闪付</p>
          </td>
          <td width="85">
            <p>1000以上</p>
          </td>
          <td width="85">
            <p>同刷卡费率</p>
          </td>
          <td rowspan="2" width="66">
            <p>条码</p>
            <p>支付</p>
          </td>
          <td width="83">
            <p>微信</p>
          </td>
          <td width="130">
            <p>
              {{ params.wechatWalletRate || "-" }}
            </p>
          </td>
        </tr>
        <tr>
          <td width="85">
            <p>1000以下</p>
          </td>
          <td width="85">
            <p>
              {{ params.smallDoubleFreeRate || "-" }}
            </p>
          </td>
          <td width="83">
            <p>支付宝</p>
          </td>
          <td width="130">
            <p>
              {{ params.alipayRate || "-" }}
            </p>
          </td>
        </tr>
        <tr>
          <td rowspan="2" width="103">
            <p>外卡支付</p>
          </td>
          <td width="85">
            <p>Visa</p>
          </td>
          <td colspan="4" width="364">
            <p>-</p>
          </td>
        </tr>
        <tr>
          <td width="85">
            <p>Master</p>
          </td>
          <td colspan="4" width="364">
            <p>-</p>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="188">
            <p>提现费</p>
          </td>
          <td colspan="4" width="364">
            <p>
              <span> <input type="checkbox" disabled />T0__ </span>
              &nbsp;&nbsp;
              <span>
                <input type="checkbox" disabled checked />D0
                <u>{{ params.settleRate || "-" }}</u>
                <u>元/笔</u>
              </span>
              &nbsp;&nbsp;
              <span> <input type="checkbox" disabled />T1 __ </span>
              &nbsp;&nbsp;
              <span> <input type="checkbox" disabled />D1 __</span>
            </p>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="188">
            <p>系统服务费（元/台）</p>
          </td>
          <td colspan="4" width="364">
            <p>
              {{ params.sysServiceFee || "-" }}
            </p>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="188">
            <p>物联网增值服务费（户/周期）</p>
          </td>
          <td colspan="4" width="364">
            <p>
              {{ params.esimServiceFee || "-" }}元/
              {{ params.esimDays || "-" }}
              天
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      注：银联云闪付包含：手机pay、银联二维码，以中国银联股份有限公司约定标准执行。
    </p>
    <p>
      <strong>2、商户</strong><strong>同意我司</strong
      ><strong>及合作方保留</strong
      ><strong>修订及调整收费方式及收费标准的权利</strong
      ><strong
        >，商户亦同意我司及合作方仅需提前通过本应用程序界面公告的方式予以公告，不做另行通知，调整内容自公告期届满（公告期为</strong
      ><strong>10日</strong><strong>）</strong><strong>起生效</strong
      ><strong
        >。在公告生效之日起，如商户继续使用本服务的，即视为商户同意我司及合作方按照调整后的收费标准向商户收取服务费用，否则商户应立即停止使用本服务。</strong
      >
    </p>
    <p>
      <strong>3、</strong
      ><strong
        >在使用本协议项下的服务过程中，商户可参与我司及合作方制定的营销奖励政策，具体奖励的规则以本应用程序界面所示或我司及合作方的官方渠道（如我司官网）发布的奖励细则为准，我司及合作方保留对活动</strong
      ><strong>奖励政策</strong><strong>进行调整的权利，但会提前</strong
      ><strong>10天将调整内容通过</strong
      ><strong
        >本应用程序界面或我司及合作方的官方渠道进行公告。如商户不接受调整的，应立即停止参与奖励政策。</strong
      >
    </p>
    <h3><strong>五、风险提示</strong></h3>
    <p>
      <strong>商户知晓并同意，商户使用本协议</strong><strong>项下服务</strong
      ><strong>可能面临如下风险，该等风险须由商户自行承担：</strong>
    </p>
    <p>
      <strong>1</strong
      ><strong
        >、监管风险：有关中国法律及相关政策、规则发生变化，导致无法实现商户的交易，商户由此可能遭受损失。</strong
      >
    </p>
    <p>
      <strong>2</strong
      ><strong
        >、违约风险：因商户的交易对手无力或无意愿按时足额履约，商户由此可能遭受损失。</strong
      >
    </p>
    <p>
      <strong>3</strong
      ><strong
        >、非因我司原因导致的任何损失，包括但不限于：因商户的操作不当、遗忘或泄露账户、密码被他人破解、商户使用的计算机系统被第三方侵入等非因我司原因而造成的损失。</strong
      >
    </p>
    <p>
      <strong>4</strong><strong>、其他不可抗力因素导致的风险，包括</strong
      ><strong>但不限于：</strong>
    </p>
    <p>
      <strong>（</strong><strong>1）</strong
      ><strong>我司公告的系统停机、检修、升级、维护等期间；</strong>
    </p>
    <p>
      <strong>（</strong><strong>2</strong
      ><strong>）本服务所依赖的电信设备出现故障不能进行数据传输的；</strong>
    </p>
    <p>
      <strong>（</strong><strong>3</strong><strong>）</strong
      ><strong
        >&nbsp;因台风、地震、海啸、洪水、停电、战争、瘟疫、恐怖袭击等不可抗力之因素，造成系统障碍不能执行业务的；</strong
      >
    </p>
    <p>
      <strong>（</strong><strong>4</strong
      ><strong>）由于黑客攻击、电信部门、</strong><strong>中国银联</strong
      ><strong
        >技术调整或故障、网站升级、其他第三方的问题等原因而造成的服务中断或者延迟。</strong
      >
    </p>
    <p>
      <strong>5、</strong
      ><strong>商户将自行承担本合同项下可能所涉的纳税义务。</strong>
    </p>
    <h3>
      <strong>六</strong><strong>、</strong><strong>商户隐私权保护</strong>
    </h3>
    <p>
      <strong>1、我司尊重并保护商户隐私，我司依法收集、使用及共享商户</strong
      ><strong>提交</strong><strong>的认证信息（</strong><strong>认证</strong
      ><strong
        >信息是以电子或者其他方式记录的能够单独或者与其他信息结合识别</strong
      ><strong>商户真实性的</strong
      ><strong>各种信息，包括商户注册账号所提供的全部</strong
      ><strong>资质</strong
      ><strong
        >信息及商户使用本应用程序的相应服务时服务提供方需要获取或提供的信息）。</strong
      >
    </p>
    <p>
      <strong
        >2、为本协议的目的，即商户能更好的使用本应用程序为商户提供的各项服务，商户签订本协议即表示商户授权同意我司依法可收集、使用及共享商户的</strong
      ><strong>相关</strong><strong>信息。</strong>
    </p>
    <p>
      <strong
        >3、我司按现有技术提供相应的安全措施来保护商户的信息，提供合理的安全保障，我司将在任何时候尽力使商户的信息不被泄露、毁损或丢失。</strong
      >
    </p>
    <p>
      <strong
        >4、商户可随时登录本应用程序的账号查询并管理该账号下商户的信息。</strong
      >
    </p>
    <p>
      <strong>5、</strong
      ><strong
        ><u
          >商户的交易相对方、商户访问的第三方网站经营者、商户使用的第三方服务提供者和由我司处接受商户的信息的第三方可能有</u
        ></strong
      ><strong><u>自己</u></strong
      ><strong><u>的隐私权保护政策；我司</u></strong
      ><strong><u>将</u></strong
      ><strong><u>尽合理努力要求</u></strong
      ><strong><u>各方</u></strong
      ><strong><u>主体对商户的信息采取保护措施，但不对</u></strong
      ><strong
        ><u
          >其是否确定采取保护措施予以承诺，并不对各方主体行为所产生的</u
        ></strong
      ><strong><u>后果承担任何责任。如果商户发现</u></strong
      ><strong><u>任何除我司之外的</u></strong
      ><strong
        ><u
          >第三方创建的网页或开发的应用程序存在风险时，建议商户终止相关操作以保护</u
        ></strong
      ><strong><u>自身</u></strong
      ><strong><u>合法权益。</u></strong>
    </p>
    <h3><strong>七</strong><strong>、</strong><strong>保密条款</strong></h3>
    <p>
      <strong>商户</strong><strong>对于</strong><strong>在</strong
      ><strong>本协议项下</strong><strong>服务</strong><strong>期间</strong
      ><strong>内</strong><strong>所</strong><strong>获知</strong
      ><strong>的属于</strong><strong>我司</strong
      ><strong>的商业和技术秘密、经营信息以及持卡人信息等保密信息</strong
      ><strong>负有</strong><strong>保密义务，商户不</strong><strong>得</strong
      ><strong>将</strong><strong>前述保密信息泄露</strong
      ><strong>给任何第三人，亦不得</strong><strong>不正当的</strong
      ><strong>利用</strong><strong>或授权他人利用前述保密</strong
      ><strong>信息</strong
      ><strong
        >。如商户违反此约定给我司或持卡人造成的损失由商户全额承担。</strong
      ><strong>商户</strong><strong>此等</strong><strong>保密义务</strong
      ><strong>于</strong><strong>本协议</strong><strong>终止</strong
      ><strong>、变更或解除后继续有效。</strong>
    </p>
    <h3><strong>八</strong><strong>、</strong><strong>知识产权</strong></h3>
    <p>
      1、我司所有电子及其他形式的相关信息，包括但不限于程序、著作、表格、图片、档案、资料、信函等均由我司或相关主体拥有知识产权。
    </p>
    <p>
      <strong>2</strong
      ><strong
        >、非经我司书面同意，商户除使用服务需要外，不得复制、篡改、传播、发行或以任何其他形式向第三方披露我司所有电子及其他形式的相关信息。</strong
      >
    </p>
    <h3><strong>九</strong><strong>、</strong><strong>违约责任</strong></h3>
    <p>
      <strong
        >商户因违反本协议及任何相关业务规则，均构成违约。因商户违约而给我司造成损失的，我司有权扣收商户的中付账户资金、关联银行账户资金或其他我司保管的商户资金，并有权依法追究商户违约责任。</strong
      >
    </p>
    <p>
      <strong>我司发</strong><strong>现</strong><strong>商户身份信息</strong
      ><strong>虚假、异常交易或有违法嫌疑时，</strong><strong>有权</strong
      ><strong>不经通知先行</strong
      ><strong
        >暂停提供本协议项下部分或全部服务，或单方终止本协议，并冻结商户待结算</strong
      ><strong>资金</strong><strong>或中付账户余额。</strong>
    </p>
    <h3>
      <strong>十</strong><strong>、</strong
      ><strong>法律适用和争议的解决</strong>
    </h3>
    <p>
      本协议签订地为中国深圳市福田区。本协议的订立、效力、解释、履行及争议解决均适用中国法律。在协议履行期间，凡由本协议引起的或与本协议有关的一切争议、纠纷，当事人应首先协商解决。<strong
        >协商不成，双方一致同意将纠纷或争议提交我司所在地即深圳市福田区有管辖权的人民法院通过诉讼方式解决。</strong
      >
    </p>
    <h3><strong>十一、协议调整</strong></h3>
    <p>
      <strong
        >我司有权随时调整本协议内容，届时我司将通过本应用程序界面公告的方式公告调整内容，不做另行通知，调整后的协议自公告届满之日（公告期为</strong
      ><strong>10日</strong><strong>）</strong><strong>起生效</strong
      ><strong>。</strong><strong>若商户</strong><strong>不</strong
      ><strong>同意调整内容的，应当立即停止使用本服务，如</strong
      ><strong>商户</strong><strong>在</strong><strong>我司调整的协议</strong
      ><strong>生效后继续使用本服务的，表示</strong><strong>商户</strong
      ><strong>已充分阅读、</strong><strong>知悉</strong
      ><strong>并接受调整后的</strong><strong>协议约束。</strong>
    </p>
    <p style="text-align: right">签名：{{ params.commissary || "-" }}</p>
    <p style="text-align: right">日期：</p>

    <p><strong>附件一：委托付款申请书</strong></p>
    <p><strong>中付支付科技有限公司：</strong></p>
    <p>
      一、因我方使用收银产品期间，服务供应商向我方提供相关运维服务，包括但不限于：收单培训、收银设备维护、以及其他收单相关增值服务等。因此，我方按照《中付商户服务协议》的约定，委托贵司从我方中付账户余额中，向服务供应商支付如下服务费：
    </p>
    <p>
      1、<strong>系统服务费：收款方：【 </strong
      ><strong>{{ params.orgName || "-" }}</strong
      ><strong>】</strong>&nbsp;
    </p>
    <p>
      2、<strong>物联网增值服务费：收款方：【</strong><strong>&nbsp;</strong
      ><strong>{{ params.orgName || "-" }} </strong><strong>】</strong>
    </p>
    <p>3、各类服务费付款金额及服务周期以《中付商户服务协议》约定项为准。</p>
    <p>
      二、以上款项均通过我方中付账户余额进行支付，余额来源包括但不限于贵司向我方结算的收银款、我方主动充值款等。服务供应商收款账户以其在贵司系统内预留的收款信息为准。
    </p>
    <p>
      三、我方同意，如因我方中付账户余额不足或系统异常等原因，导致贵司未能按照本委托完成付款时，我方允许贵司在我方中付账户余额充足情况下<strong>，</strong>重新发起付款指令，以完成委托付款。
    </p>
    <p>
      四、我方知晓，贵司系受我方委托进行付款，故若因以上委托事项导致的任何经济或法律纠纷，均与贵司无关，由我方与收款方协商解决，相关责任与损失全部由我方自行承担。
    </p>
    <p>
      五、本委托申请书系《中付商户服务协议》附件，有效期与《中付商户服务协议》有效期一致；未作约定的，仍以《中付商户服务协议》为准。
    </p>
    <p>
      <strong>附件二：物联网增值服务协议</strong>
    </p>
    <p>
      尊敬的客户（以下简称&ldquo;客户&rdquo;或&ldquo;您&rdquo;），你所申请使用的物联网增值服务，是由【{{
        params.orgName || "-"
      }}】（以下简称&ldquo;服务方&rdquo;）的物联网服务平台（以下简称&ldquo;平台&rdquo;）向您提供。平台所提供的增值服务内容包含物联网链接服务及智慧支付与播报定位物联网服务（以下合并简称为物联网服务）。
    </p>
    <p>
      <strong>【审</strong><strong>慎</strong><strong>阅读】</strong
      ><strong>客户在</strong><strong>点击同意</strong><strong>本</strong
      ><strong>协议</strong><strong>前，应当认真阅读本协议。</strong
      ><strong><u>请</u></strong
      ><strong><u>您务必审慎阅读，充分理解</u></strong
      ><strong><u>全部协议</u></strong
      ><strong><u>各条款内容，特别是免除</u></strong
      ><strong><u>或</u></strong
      ><strong><u>限制</u></strong
      ><strong><u>责任</u></strong
      ><strong><u>的条款</u></strong
      ><strong><u>，</u></strong
      ><strong><u>法律</u></strong
      ><strong><u>适用</u></strong
      ><strong><u>和争议解决条款</u></strong
      ><strong><u>。</u></strong
      ><strong><u>免除或限制</u></strong
      ><strong><u>责任</u></strong
      ><strong><u>条款</u></strong
      ><strong><u>会使用</u></strong
      ><strong><u>粗体或</u></strong
      ><strong><u>下划线</u></strong
      ><strong><u>标识</u></strong
      ><strong><u>，</u></strong
      ><strong><u>请您重点阅读。</u></strong>
    </p>
    <p>
      <strong>【签约动作】当您</strong><strong>通过平台</strong
      ><strong>提供</strong><strong>的网络页面</strong
      ><strong>、手机客户端或其它应用等对本协议点击勾选</strong
      ><strong>后，</strong><strong><u>即视为您已</u></strong
      ><strong><u>充分阅读</u></strong
      ><strong><u>，</u></strong
      ><strong><u>理解并</u></strong
      ><strong><u>同意接受本协议的全部</u></strong
      ><strong><u>内容，</u></strong
      ><strong><u>同意受本协议所有条款的约束，</u></strong
      ><strong><u>与</u></strong
      ><strong><u>服务方达成</u></strong
      ><strong><u>一致并使用</u></strong
      ><strong><u>平台为您</u></strong
      ><strong><u>提供的服务</u></strong
      ><strong><u>。</u></strong
      ><strong><u>如果</u></strong
      ><strong><u>您</u></strong
      ><strong><u>不同意本协议或</u></strong
      ><strong><u>其中</u></strong
      ><strong><u>任何条款约定，</u></strong
      ><strong><u>您</u></strong
      ><strong><u>应</u></strong
      ><strong><u>立即</u></strong
      ><strong><u>停止</u></strong
      ><strong><u>签署并放弃使用服务</u></strong
      ><strong><u>。</u></strong>
    </p>
    <p>
      <strong>【协议变更方式】</strong><strong>平台</strong
      ><strong>保留随时修改协议</strong><strong>条款、以及</strong
      ><strong>对平台</strong><strong>的</strong><strong>网络页面</strong
      ><strong>、手机客户端或其它应用</strong><strong>进行更</strong
      ><strong>名</strong><strong>或服务调</strong><strong>整</strong
      ><strong>之权利</strong><strong>。</strong><strong><u>当</u></strong
      ><strong><u>发生上述情形</u></strong
      ><strong><u>时，</u></strong
      ><strong><u>平台将通过平台提供的</u></strong
      ><strong><u>网络页面</u></strong
      ><strong><u>、手机客户端或其它应用</u></strong
      ><strong
        ><u
          >显著位置公告最新之变更事项，而无需另作个别通知，变更事项在公告届满10日起生效</u
        ></strong
      ><strong><u>。</u></strong
      ><strong><u>请您及</u></strong
      ><strong><u>时查看并</u></strong
      ><strong><u>审慎阅读公示的通</u></strong
      ><strong><u>知、</u></strong
      ><strong
        ><u
          >单项协议、规则或政策，操作时的提示（包括通过移动客户端应用程序）等条款。</u
        ></strong
      ><strong><u>若您在</u></strong
      ><strong><u>公示内容</u></strong
      ><strong><u>变更生效后继续使用</u></strong
      ><strong><u>本</u></strong
      ><strong><u>服务的，表示您已充分阅读、理解并接受</u></strong
      ><strong><u>公示的</u></strong
      ><strong><u>内容，也将遵循变更后</u></strong
      ><strong><u>的</u></strong
      ><strong><u>协议</u></strong
      ><strong><u>或</u></strong
      ><strong><u>服务内容</u></strong
      ><strong><u>使用本</u></strong
      ><strong><u>服务；若您不同意变更后的</u></strong
      ><strong><u>协议</u></strong
      ><strong><u>或服务内容，您应在变更生效前停止使用</u></strong
      ><strong><u>本</u></strong
      ><strong><u>服务</u></strong
      ><strong><u>。</u></strong>
    </p>
    <p>
      <strong><u>【协议内容】现就服务方为您提供物联网服务支持等</u></strong
      ><strong><u>事项所订立的协议</u></strong
      ><strong><u>，内容如下：</u></strong>
    </p>
    <h3><strong>一、服务内容</strong></h3>
    <p>服务方为您提供的物联网服务，包括以下具体内容：</p>
    <p>
      （1）提供您所使用的支付硬件设备网络连接服务、支付硬件设备适配及数据传输服务等服务。
    </p>
    <p>
      （2）平台为您提供网络连接服务为4G物联网基础通道服务，如您在仅支持2G的支付硬件设备上使用，服务方不承诺2G网络服务质量。
    </p>
    <h3><strong>二、收费标准</strong></h3>
    <p>
      <strong
        ><u
          >客户在使用本服务时，需向服务方支付物联网增值服务费，包含：</u
        ></strong
      >
    </p>
    <p>
      <strong><u>（1）年租费：即每年向服务方支付的年度服务费。</u></strong>
    </p>
    <p>
      <strong
        ><u>（2）增值服务费：即按照使用量向服务方支付的增值服务费。</u></strong
      >
    </p>
    <p>
      <strong><u>收费标准</u></strong
      ><strong><u>以</u></strong
      ><strong><u>平台</u></strong
      ><strong><u>的</u></strong
      ><strong><u>网络页面</u></strong
      ><strong><u>、手机客户端或其它应用</u></strong
      ><strong><u>服务页面</u></strong
      ><strong><u>所示、或双方另行约定</u></strong
      ><strong><u>的</u></strong
      ><strong><u>为准，客户</u></strong
      ><strong><u>同意</u></strong
      ><strong><u>服务方保留</u></strong
      ><strong><u>修订及调整收费方式及收费标准的权利</u></strong
      ><strong
        ><u
          >，客户亦同意服务方仅需提前通过本应用程序界面公告的方式予以公告，不做另行通知，调整的内容自公告期届满（公告期为</u
        ></strong
      ><strong><u>7日</u></strong
      ><strong><u>）</u></strong
      ><strong><u>起生效</u></strong
      ><strong
        ><u
          >。在公告生效之日起，如客户继续使用本服务的，即视为客户同意服务方按照调整后的收费标准向客户收取服务费用，否则客户应立即停止使用本服务。</u
        ></strong
      >
    </p>
    <h3><strong>三、确认及承诺</strong></h3>
    <p>
      （1）您需承诺不利用服务方提供的物联网服务进行任何违反法律以及危害国家利益的行为，保证不对服务方的公众业务产生不良影响。否则服务方有权单方解除本协议。
    </p>
    <p>
      （2）您需承诺按照要求完成实名登记，并在使用物联网服务过程中遵守国家有关法律法规和政府电信主管部门有关管理规定，否则，服务方有权终止本协议。
    </p>
    <p>
      （3）您同意如您的支付硬件设备连续180天未使用物联网服务，服务方有权关闭该设备的物联网链接服务而无需承担责任。
    </p>
    <p>
      （4）您同意服务方向您提供的物联网增值服务，仅限于您实际使用的支付硬件设备并在提供服务之日起对该支付硬件设备进行绑定，如您未经服务方允许将物联网卡从绑定的支付硬件设备中拔出，服务方有权关闭该设备及对应物联网卡的物联网服务而无需承担责任。
    </p>
    <h3><strong>四、风险提示</strong></h3>
    <p>
      客户知晓并同意，客户使用本协议项下服务可能面临如下风险，该风险须由客户自行承担：
    </p>
    <p>
      <u>1</u
      ><strong
        ><u
          >.非因服务方原因导致的无法正常使用服务，包括但不限于：因客户的操作不当、客户的支付硬件设备损坏等非因我司原因而造成的损失。</u
        ></strong
      >
    </p>
    <p>2.其他不可抗力因素导致的风险，包括但不限于：</p>
    <p>（1）服务方公告的系统停机、检修、升级、维护等期间；</p>
    <p>（2）本服务所依赖的电信设备出现故障不能进行数据传输的；</p>
    <p>
      （3）因台风、地震、海啸、洪水、停电、战争、瘟疫、恐怖袭击等不可抗力之因素，造成系统障碍不能执行业务的；
    </p>
    <p>
      （4）由于黑客攻击、电信部门、网站升级、其他第三方的问题等原因而造成的服务中断或者延迟。
    </p>
    <h3><strong>五、法律适用和争议的解决</strong></h3>
    <p>
      本协议签订地为中国深圳市前海深港合作区。本协议的订立、效力、解释、履行及争议的解决均适用中国法律。在协议履行期间，凡由本协议引起的或与本协议有关的一切争议、纠纷，当事人应首先协商解决。<strong
        ><u
          >协商不成，双方一致同意将纠纷或争议提交我司所在地即深圳市前海深港合作区有管辖权的人民法院通过诉讼方式解决。</u
        ></strong
      >
    </p>
    <p><strong>附件三：账户服务协议</strong></p>
    <p>
      一、账户支付服务是指我司为商户提供的、用于已开通中付账户的客户向商户支付款项时，提供中付账户及密码，通过中付支付账户（包括中付支付账户现有余额，以及经客户已绑定的银行卡、中付应用账户划转至中付支付账户），即可完成款项支付的电子支付服务。
    </p>
    <p>
      二、商户应在我司提供的电子支付服务上，以Email地址或手机号作为商户的<strong>中付账户</strong>标识，并采取必要和有效的保密措施，妥善且正确地保管、使用、维护该中付账户以及账户密码。非我司原因致使商户账户密码泄漏以及因商户保管、使用、维护不当造成损失的，我司无须承担与此有关的任何责任。
    </p>
    <p>三、商户申请开通以下中付账户：</p>
    <table border="1" style="border-collapse: collapse">
      <tbody>
        <tr>
          <td width="55">
            <p>序号</p>
          </td>
          <td width="314">
            <p>中付账户（E-mail/手机号）</p>
          </td>
        </tr>
        <tr>
          <td width="55">
            <p>1</p>
          </td>
          <td width="314">
            <p>{{ params.bankPhone || "-" }}</p>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      四、商户不得擅自转让、出售、出借中付账户，否则，由此产生的一切风险由商户承担，我司有权终止本协议。
    </p>
    <p>
      五、我司为商户建立中付账户并提供安全、稳定的电子支付服务，我司为商户提供中付账户的基本管理功能，包括账户操作员权限设置、余额明细、交易历史查询等。
    </p>
    <p>六、商户可以查询并保存其中付账户内最近二年内的历史交易信息。</p>
    <p>
      七、商户公司拟解散/注销的，商户应当在公司解散/注销之前向我司提出终止本协议，并结清其中付账户中的资金。否则，由此产生的一切后果均由商户承担。
    </p>
    <p>
      八、本委托申请书系《中付商户服务协议》附件，有效期与《中付商户服务协议》有效期一致。
    </p>
    <p>
      <strong
        >附件四：银行账户/支付账户涉电信网络新型违法犯罪法律责任及防范提示告知书</strong
      >
    </p>
    <p>
      为打击治理电信网络新型违法犯罪，保护人民群众财产安全，根据相关规定，现将开立、使用人民币银行结算账户或支付账户（以下统称&ldquo;账户&rdquo;）的法律责任及防范提示告知如下：
    </p>
    <p>
      一、个人申请开立账户时，应提供本人真实身份证件，如实填写个人信息；单位申请开立账户时，应按照《人民币银行结算账户管理办法》（中国人民银行令〔2003〕第5号）等规定提交相关证明文件。
    </p>
    <p>
      二、任何单位和个人不得出租、出借、出售、购买账户。发生上述行为构成电信网络诈骗犯罪的，依据《最高人民法院
      最高人民检察院
      公安部关于办理电信网络诈骗等刑事案件适用法律若干问题的意见》（法发〔2016〕32号）第四条的规定，对相关责任人以电信网络诈骗共同犯罪论处。
    </p>
    <p>
      三、对经设区的市级及以上公安机关认定的出租、出借、出售、购买账户的单位和个人及相关组织者，假冒他人身份或者虚构代理关系开立账户的单位和个人，银行机构、非银行支付机构将依据《中国人民银行关于加强支付结算管理防范电信网络新型违法犯罪有关事项的通知》（银发〔2016〕261号），5年内暂停其银行账户非柜面业务、支付账户所有业务，3年内不得为其新开立账户。中国人民银行将相关单位和个人信息移送金融信用信息基础数据库并向社会公布。
    </p>
    <p>
      四、单位或个人申请开立账户时，如有以下情形应立即停止开户业务，请求银行机构、非银行支付机构工作人员帮助或拨打110报警：
    </p>
    <p>
      (一）自称是&ldquo;公安、检察、法院、海关、医保、社保&rdquo;等执法人员，要求将资金转移至&ldquo;安全账户&rdquo;或验资的；
    </p>
    <p>
      （二）自称是&ldquo;家人、朋友、领导、老师、熟人、恋人&rdquo;等各类关系人，要求进行存钱、汇款或转账的；
    </p>
    <p>
      (三）自称可以办理&ldquo;退货、退税、退票、发放补贴、中奖兑换、积分兑换、提高信用卡额度、刷信誉返利、低息高额贷款&rdquo;等各类&ldquo;获利&rdquo;行为，要求进行存钱、汇款或转账的；
    </p>
    <p>
      (四）使用&ldquo;举报信、PS照片、黑社会报复&rdquo;等各类&ldquo;恐吓&rdquo;手段，要求进行存钱、汇款或转账的；
    </p>
    <p>（五）其他类似情形。</p>
    <p>
      <strong
        >商户确认：已知晓上述账户涉电信网络新型违法犯罪的法律责任及防范提示告知内容，将依法申请开立、使用并妥善保管本人（或本单位）账户。</strong
      >
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {},
    };
  },

  mounted() {
    // 原生app 调用方法
    window.getAgreementInfo = (result) => {
      if (typeof result === "string") {
        this.params = JSON.parse(result);
      } else {
        this.params = result;
      }
    };
  },

  methods: {},
};
</script>
<style lang="less" scoped>
.content-box {
  margin: 20px;
  padding-bottom: 30px;
  word-break: break-all;
  font-size: 28px;
  font-family: "Microsoft YaHei";
  table {
    border-collapse: collapse;
    width: 100%;
  }
}
</style>
